.FeedBack-Dialog {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 324px;
  height: 150px;
}
.FeedBack-grid-div6 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}
.FeedBack-root-grid {
  height: 100%;
}
.FeedBack-grid-div7 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.FeedBack-footer-root-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-top: 1px solid #b3bac5;
}
.FeedBack-line-div {
  border-top: 1px solid #b3bac5;
  height: 100%;
  width: 1px;
  background: #b3bac5;
}
.FeedBack-footer-div2 {
  margin-top: 5px;
}
.FeedBack-btn {
  background: "white";
  text-transform: initial;
  width: "100%";
  height: "100%";
  white-space: "nowrap";
  cursor: pointer;
}
