@import "../Colors/color_variables.scss";

.primary-button {
  background-color: $primary-color-2 !important;
  color: white !important;
  text-transform: initial !important;
  border-radius: 8px;
  font-weight: 500;
  &.large {
    width: 96px;
    height: 48px;
    text-transform: initial !important;
    border-radius: 8px;
  }

  &.medium {
    width: 96px;
    height: 40px;
    text-transform: initial !important;
    border-radius: 8px;
  }

  &.small {
    width: 96px;
    height: 32px;
    text-transform: initial !important;
    border-radius: 8px;
  }

  :active {
    background-color: none !important;
    color: white !important;
    text-transform: initial !important;
    border-radius: 8px;
    font-weight: 500;
  }

  &:disabled {
    color: $secondary-color-1 !important;
    text-transform: initial !important;
    background-color: $secondary-color-6 !important;
    border-radius: 8px;
    font-weight: 500;
  }

  .alert-ellipse {
    width: 8px;
    height: 8px;
    left: 0px;
    top: 12px;
    border-radius: 8px;
    background-color: $primary-color-1 !important;
    text-transform: initial !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
  }

  .button-label {
    font-family: Poppins, sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: none;
    text-transform: initial !important;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }
}

.secondary-button {
  background-color: $secondary-color-7 !important;
  border: 2px solid $primary-color-2 !important;
  color: $primary-color-2 !important;
  text-transform: inherit;
  border-radius: 8px;
  font-weight: 500;
  &.large {
    width: 96px;
    height: 48px;
    text-transform: inherit;
    border-radius: 8px;
    font-weight: 500;
  }

  &.medium {
    width: 96px;
    height: 40px;
    text-transform: inherit;
    border-radius: 8px;
    font-weight: 500;
  }

  &.small {
    width: 96px;
    height: 32px;
    text-transform: inherit;
    border-radius: 8px;
    font-weight: 500;
    background-color: $secondary-color-21 !important;
  }

  :hover {
    background-color: rgba(155, 81, 224, 0.1);
    text-transform: inherit;
    border-radius: 8px;
    font-weight: 500;
  }

  &:disabled {
    color: $secondary-color-3 !important;
    background-color: rgba(239, 241, 245, 0.5) !important;
    text-transform: inherit;
    border-radius: 8px;
    font-weight: 500;
    border: 2px solid $secondary-color-3 !important;
  }

  .alert-ellipse {
    width: 8px;
    height: 8px;
    left: 0px;
    top: 12px;
    border-radius: 8px;
    background-color: $secondary-color-9;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
  }

  .button-label {
    font-family: Poppins, sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    content: none;
    flex: none;
    background-color: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }
}

.ghost-button {
  background-color: $secondary-color-8 !important;
  color: $primary-color-2 !important;

  &.large {
    width: 96px;
    height: 48px;
  }

  &.medium {
    width: 96px;
    height: 40px;
  }

  &.small {
    width: 96px;
    height: 32px;
  }

  &:active {
    color: $primary-color-2 !important;
  }

  &:disabled {
    color: $secondary-color-3 !important;
  }

  .alert-ellipse {
    width: 8px;
    height: 8px;
    left: 0px;
    top: 12px;
    border-radius: 8px;
    background-color: $secondary-color-9 !important;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
  }

  .button-label {
    font-family: Poppins, sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    content: none;

    flex: none;
    background-color: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }
}

.mockup-button {
  background-color: $primary-color-2 !important;
  color: #ffffff !important;
  text-transform: initial !important;
  border-radius: 8px !important;
  font-weight: 500;
  font-family: Poppins, sans-serif !important;
  height: "40px !important";
  :active {
    background-color: none !important;
    font-family: Poppins, sans-serif !important;
    color: #ffffff !important;
    text-transform: initial !important;
  }

  &:disabled {
    color: $secondary-color-1 !important;
    text-transform: initial !important;
    font-family: Poppins, sans-serif !important;
    background-color: $secondary-color-6 !important;
  }

  .alert-ellipse {
    width: 8px;
    height: 8px;
    left: 0px;
    top: 12px;
    border-radius: 8px;
    background-color: $primary-color-1 !important;
    text-transform: initial !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
  }

  .button-label {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: none;
    text-transform: initial !important;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }
}
.dot {
  height: 10px;
  width: 10px;
  margin-left: 5px;
  background-color: $secondary-color-5;
  border-radius: 50%;
  display: inline-block;
}
.online {
  background-color: $secondary-color-13;
}

p.dot_1::after {
  height: 10px;
  width: 10px;
  margin-left: 5px;
  position: absolute;
  margin-top: 5px;
  background-color: $secondary-color-5;
  border-radius: 50%;
  display: inline-block;
  content: "";
}

p.online_1::after {
  background-color: $secondary-color-13;
}
