$background-color: #f8f8f8;
$primary-color-1: #172b4d;
$primary-color-2: #9b51e0;
$primary-color-3: #010353;
$primary-color-4: #ffffff;
$primary-color-5: #000000;
$secondary-color-1: #344563;
$secondary-color-2: #5e6c84;
$secondary-color-3: #5e6c84;
$secondary-color-4: #344563;
$secondary-color-5: #b3bac5;
$secondary-color-6: #d1d6dd;
$secondary-color-7: #ebecf0;
$secondary-color-8: #eff1f5;
$secondary-color-9: #e52727;
$secondary-color-10: #f2994a;
$secondary-color-11: #f2c94c;
$secondary-color-13: #219653;
$secondary-color-14: #27ae60;
$secondary-color-15: #56c596;
$secondary-color-16: #1366d6;
$secondary-color-17: #2d9cdb;
$secondary-color-18: #56ccf2;
$secondary-color-19: #bb6bd9;
$secondary-color-20: #1697d6;
$secondary-color-21: #8044b9;
$secondary-color-22: #ffffff;
$secondary-color-23: #8993a4;
$secondary-color-24: #d86500;
$secondary-color-25: #d10000;
$secondary-color-26: #030303;
$secondary-color-27: #eb7b18;
$secondary-color-28: #56c596;
