.callerWindowContainer {
  width: 60vw;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #d1d6dd;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  .callerWindowDesign {
    display: grid;
    grid-template-rows: 24px 38vw;
    grid-template-columns: 100%;
    .callerWindowHeader {
      background: #eff1f5;
      display: grid;
      grid-template-columns: 100%;
      place-content: left;
      grid-template-rows: 24px;
      border-radius: 10px 10px 0px 0px;
      .headerIcons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-left: 8px;
        place-content: center;
        .redIcon {
          width: 10px;
          height: 10px;
          background: #e52727;
          border-radius: 50%;
        }
        .yellowIcon {
          background: #f2c94c;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
        .greenIcon {
          width: 10px;
          background: #56c596;
          height: 10px;
          border-radius: 50%;
        }
      }
      .headerName {
        display: grid;
        grid-template-columns: 100%;
        place-content: center;
        p {
          font-family: Poppins, sans-serif;
          font-size: 12px;
          color: #172b4d;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: -0.20000000298023224px;
          margin: 0;
        }
      }
    }
    .bodyContent {
      width: 100%;
      padding: 15px;
      display: grid;
      .bodyContentBG {
        background: #172b4d;
        border-radius: 10px;
      }
    }
  }
}

.groupcallerWindowContainer {
  width: 60vw;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #d1d6dd;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  .groupcallerWindowDesign {
    display: grid;
    grid-template-rows: 24px 38vw;
    grid-template-columns: 100%;
    .groupcallerWindowHeader {
      background: #eff1f5;
      display: grid;
      grid-template-columns: 100%;
      place-content: left;
      grid-template-rows: 24px;
      border-radius: 10px 10px 0px 0px;
      .headerIcons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-left: 8px;
        place-content: center;
        .redIcon {
          width: 10px;
          height: 10px;
          background: #e52727;
          border-radius: 50%;
        }
        .yellowIcon {
          background: #f2c94c;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
        .greenIcon {
          width: 10px;
          background: #56c596;
          height: 10px;
          border-radius: 50%;
        }
      }
      .headerName {
        display: grid;
        grid-template-columns: 100%;
        place-content: center;
        p {
          font-family: Poppins, sans-serif;
          font-size: 12px;
          color: #172b4d;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: -0.20000000298023224px;
          margin: 0;
        }
      }
    }
    .bodyContent {
      width: 100%;
      padding: 15px;
      display: grid;
      .bodyContentBG {
        background: #172b4d;
        border-radius: 10px;
      }
    }
  }
}
.hiddenclassT.hidden {
  display: none;
}

.lds-spinner1 {
  color: official;
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  top: 50%;
  transform: translate(0, -50%);
}
.lds-spinner1 div {
  transform-origin: 40px 40px;
  animation: lds-spinner1a 1.2s linear infinite;
}
.lds-spinner1 div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner1 div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner1 div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner1 div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner1 div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner1 div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner1 div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner1 div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner1 div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner1 div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner1 div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner1 div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner1 div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner1a {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ProviderStatus {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 20px;
  color: #ffffff;
  transform: translate(-50%, -50%);
  // font-size: 30px;
  // animation: providerStatus 1.2s linear infinite;
}
@keyframes providerStatus {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.groupCallWindowContainer {
  width: 70vw;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #d1d6dd;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}

.closeCallWindow {
  position: absolute;
  height: 20px;
  right: 10px;
  aspect-ratio: 1;
  display: grid;
  place-self: center;
  place-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
  &:hover {
    cursor: pointer;
    border-radius: 50%;
    background-color: #e5272780;
  }
}
