body {
  margin: 0;
  background-color: #f8f8f8;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
@font-face {
  font-family: "PoppinsBlack";
  src: local("PoppinsBlack"),
    url(./Fonts/Poppins/Poppins-Black.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsBlackItalic";
  src: local("PoppinsBlackItalic"),
    url(./Fonts/Poppins/Poppins-BlackItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url(./Fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsBoldItalic";
  src: local("PoppinsBoldItalic"),
    url(./Fonts/Poppins/Poppins-BoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsExtraBold";
  src: local("PoppinsExtraBold"),
    url(./Fonts/Poppins/Poppins-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsExtraBoldItalic";
  src: local("PoppinsExtraBoldItalic"),
    url(./Fonts/Poppins/Poppins-ExtraBoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsExtraLight";
  src: local("PoppinsExtraLight"),
    url(./Fonts/Poppins/Poppins-ExtraLight.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsExtraLightItalic";
  src: local("PoppinsExtraLightItalic"),
    url(./Fonts/Poppins/Poppins-ExtraLightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsItalic";
  src: local("PoppinsItalic"),
    url(./Fonts/Poppins/Poppins-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"),
    url(./Fonts/Poppins/Poppins-Light.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsLightItalic";
  src: local("PoppinsLightItalic"),
    url(./Fonts/Poppins/Poppins-LightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"),
    url(./Fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsMediumItalic";
  src: local("PoppinsMediumItalic"),
    url(./Fonts/Poppins/Poppins-MediumItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url(./Fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"),
    url(./Fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsSemiBoldItalic";
  src: local("PoppinsSemiBoldItalic"),
    url(./Fonts/Poppins/Poppins-SemiBoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsThin";
  src: local("PoppinsThin"),
    url(./Fonts/Poppins/Poppins-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "PoppinsThinItalic";
  src: local("PoppinsThinItalic"),
    url(./Fonts/Poppins/Poppins-ThinItalic.ttf) format("truetype");
}
// @font-face {
//   font-family: "SF Pro Text";
//   src: local("SF Pro Text"), url(./Fonts/SF_Pro/SF_Pro.ttf) format("truetype");
// }

@font-face {
  font-family: "SF Pro Text";
  src: url("./Fonts/SFPro/SFProText-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./Fonts/SFPro/SFProText-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./Fonts/SFPro/SFProText-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./Fonts/SFPro/SFProText-RegularItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./Fonts/SFPro/SFProText-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./Fonts/SFPro/SFProText-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./Fonts/SFPro/SFProText-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./Fonts/SFPro/SFProText-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./Fonts/SFPro/SFProText-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./Fonts/SFPro/SFProText-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./Fonts/SFPro/SFProText-Heavy.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./Fonts/SFPro/SFProText-HeavyItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
