.notifyTitle {
  margin-left: 30px;
  margin-top: 19px;
}

::-webkit-scrollbar {
  border: none;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px;
}
.setBackgroundColor:hover {
  background-color: rgba(155, 81, 224, 0.05) !important;
}
// hr {
//   display: block !important;
//   height: 1px !important;
//   border: 0 !important;
//   border-top: 1px solid #EBECF0 !important;
//   padding: 0 !important;
// }
