:root {
  --one-row: calc(38vw - 30px);
  --two-rows: repeat(2, calc(38vw / 2 - 15px));
  --three-rows: repeat(3, calc(38vw / 3 - 10px));
  --four-rows: repeat(4, calc(38vw / 4 - 7.5px));
  --one-row--min: calc(257px - 30px);
  --two-rows--min: repeat(2, calc(257px / 2 - 15px));
  --three-rows--min: repeat(3, calc(257px / 3 - 10px));
  --four-rows--min: repeat(4, calc(257px / 4 - 7.5px));
}

@media (orientation: portrait) {
  :root {
    --one-row: calc(51vh - 30px);
    --two-rows: repeat(2, calc(51vh / 2 - 15px));
    --three-rows: repeat(3, calc(51vh / 3 - 10px));
    --four-rows: repeat(4, calc(51vh / 4 - 7.5px));
  }
}

$videos: (
  "1": "A",
  "2": "B",
  "3": "C",
  "4": "D",
  "5": "E",
  "6": "F",
  "7": "G",
  "8": "H",
);

@each $num, $letter in $videos {
  .root-user-div-label-#{$num} {
    grid-area: unquote($letter);
  }
}

.agoraGroupcallgrid {
  display: grid;
}

.agoraGroupcallgrid--1 {
  grid-template-rows: var(--one-row);
  grid-template-areas: "A";
  grid-template-columns: 1fr;

  // @media (orientation: portrait) {
  //   grid-template-areas: "A";
  //   grid-template-columns: 1fr;
  // }
}

.agoraGroupcallgrid--2 {
  grid-template-rows: var(--one-row);
  grid-template-areas: "A B";
  grid-template-columns: 1fr 1fr;

  // @media (orientation: portrait) {
  //   grid-template-rows: var(--two-rows);
  //   grid-template-areas:
  //     "A"
  //     "B";
  //   grid-template-columns: 1fr;

  //   // @media (orientation: portrait) {
  //   //   grid-template-areas:
  //   //     "A";
  //   //   grid-template-columns: 1fr;
  //   // }
  // }
}

.agoraGroupcallgrid--3 {
  grid-template-rows: var(--two-rows);
  grid-template-areas:
    "A B"
    "A C";
  grid-template-columns: 1fr 1fr;

  // @media (orientation: portrait) {
  //   grid-template-rows: var(--three-rows);
  //   grid-template-areas:
  //     "A B";
  //   grid-template-columns: 1fr 1fr;

  //   // @media (orientation: portrait) {
  //   //   grid-template-rows: var(--two-rows);
  //   //   grid-template-areas:
  //   //     "A"
  //   //     "B";
  //   //     grid-template-columns: 1fr;
  //   // }
  // }
}

.agoraGroupcallgrid--4 {
  grid-template-rows: var(--two-rows);
  grid-template-areas:
    "A B"
    "C D";
  grid-template-columns: 1fr 1fr;
}

// .agoraGroupcallgrid--5 {
//   grid-template-rows: var(--two-rows);
//   grid-template-areas:
//     "A A B B C C"
//     "D D D E E E";
//   //grid-template-columns: 1fr 1fr 1fr;
//   grid-template-columns: initial;

//   // @media (orientation: portrait) {
//   //   grid-template-rows: var(--three-rows);
//   //   grid-template-areas:
//   //     "A A B B"
//   //     "C C D D"
//   //     "E E E E";
//   //   grid-template-columns: 1fr 1fr;

//   //   // @media (orientation: portrait) {
//   //   //   grid-template-rows: var(--three-rows);
//   //   //   grid-template-areas:
//   //   //     "A"
//   //   //     "B"
//   //   //     "C";
//   //   //     grid-template-columns: 1fr;
//   //   // }
//   // }
// }

// .agoraGroupcallgrid--6 {
//   grid-template-rows: var(--two-rows);
//   grid-template-areas:
//     "A B C"
//     "D E F";
//   grid-template-columns: 1fr 1fr 1fr;

//   // @media (orientation: portrait) {
//   //   grid-template-rows: var(--three-rows);
//   //   grid-template-areas:
//   //     "A B"
//   //     "C D"
//   //     "E F";
//   //   grid-template-columns: 1fr 1fr;
//   // }
// }

.agoraGroupcallgrid--5 {
  grid-template-rows: var(--two-rows);
  grid-template-areas:
    "A A B B C C"
    "D D D E E E";
  //grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: initial;

  // @media (orientation: portrait) {
  //   grid-template-rows: var(--three-rows);
  //   grid-template-areas:
  //     "A A B B"
  //     "C C D D"
  //     "E E E E";
  //   grid-template-columns: 1fr 1fr;
  // }
}

.agoraGroupcallgrid--6 {
  grid-template-rows: var(--two-rows);
  grid-template-areas:
    "A B C"
    "D E F";
  grid-template-columns: 1fr 1fr 1fr;

  // @media (orientation: portrait) {
  //   grid-template-rows: var(--three-rows);
  //   grid-template-areas:
  //     "A B"
  //     "C D"
  //     "E F";
  //   grid-template-columns: 1fr 1fr;
  // }
}

.agoraGroupcallgrid--7 {
  grid-template-rows: var(--two-rows);
  grid-template-areas:
    "A A A B B B C C C D D D"
    "E E E E F F F F G G G G";
  grid-template-columns: 1fr 1fr 1fr 1fr;

  // @media (orientation: portrait) {
  //   grid-template-rows: var(--three-rows);
  //   grid-template-areas:
  //     "A A A B B B"
  //     "C C C D D D"
  //     "E E F F G G";
  //   grid-template-columns: 1fr 1fr;
  // }
}

.agoraGroupcallgrid--8 {
  grid-template-rows: var(--two-rows);
  grid-template-areas:
    "A B C D"
    "E F G H";
  grid-template-columns: 1fr 1fr 1fr 1fr;

  // @media (orientation: portrait) {
  //   grid-template-rows: var(--four-rows);
  //   grid-template-areas:
  //     "A B C D"
  //     "E F G H";
  //   grid-template-columns: 1fr 1fr 1fr 1fr;

  //   // @media (orientation: portrait) {
  //   //   grid-template-rows: var(--four-rows);
  //   //   grid-template-areas:
  //   //     "A B"
  //   //     "C D"
  //   //     "E F"
  //   //     "G H";
  //   //   grid-template-columns: 1fr 1fr;
  //   // }
  // }
}

// :root {
//   --one-row--min: calc(257px - 30px);
//   --two-rows--min: repeat(2, calc(257px / 2 - 15px));
//   --three-rows--min: repeat(3, calc(257px / 3 - 10px));
//   --four-rows--min: repeat(4, calc(257px / 4 - 7.5px));
// }

// $videos: (
//   "1": "A",
//   "2": "B",
//   "3": "C",
//   "4": "D",
//   "5": "E",
//   "6": "F",
//   "7": "G",
//   "8": "H",
// );

// @each $num, $letter in $videos {
//   .video-#{$num} {
//     grid-area: unquote($letter);
//   }
// }

// .agoraGroupcallgrid {
//   display: grid;
// }

.agoraGroupcallgridmin--1 {
  grid-template-rows: var(--one-row--min);
  grid-template-areas: "A";
  grid-template-columns: 1fr;

  // @media (orientation: portrait) {
  //   grid-template-areas: "A";
  //   grid-template-columns: 1fr;
  // }
}

.agoraGroupcallgridmin--2 {
  grid-template-rows: var(--one-row--min);
  grid-template-areas: "A B";
  grid-template-columns: 1fr 1fr;

  // @media (orientation: portrait) {
  //   grid-template-rows: var(--two-rows--min);
  //   grid-template-areas:
  //     "A"
  //     "B";
  //   grid-template-columns: 1fr;

  //   // @media (orientation: portrait) {
  //   //   grid-template-areas:
  //   //     "A";
  //   //   grid-template-columns: 1fr;
  //   // }
  // }
}

.agoraGroupcallgridmin--3 {
  grid-template-rows: var(--two-rows--min);
  grid-template-areas:
    "A B"
    "A C";
  grid-template-columns: 1fr 1fr;

  // @media (orientation: portrait) {
  //   grid-template-rows: var(--three-rows--min);
  //   grid-template-areas:
  //     "A B";
  //   grid-template-columns: 1fr 1fr;

  //   // @media (orientation: portrait) {
  //   //   grid-template-rows: var(--two-rows--min);
  //   //   grid-template-areas:
  //   //     "A"
  //   //     "B";
  //   //   grid-template-columns:1fr;
  //   // }
  // }
}

.agoraGroupcallgridmin--4 {
  grid-template-rows: var(--two-rows--min);
  grid-template-areas:
    "A B"
    "C D";
  grid-template-columns: 1fr 1fr;
}

// .agoraGroupcallgridmin--5 {
//   grid-template-rows: var(--two-rows--min);
//   grid-template-areas:
//     "A A B B C C"
//     "D D D E E E";
//   //  grid-template-columns: 1fr 1fr 1fr;
//   grid-template-columns: initial;

//   // @media (orientation: portrait) {
//   //   grid-template-rows: var(--three-rows--min);
//   //   grid-template-areas:
//   //     "A A B B"
//   //     "C C D D"
//   //     "E E E E";
//   //   grid-template-columns: 1fr 1fr;

//   //   // @media (orientation: portrait) {
//   //   //   grid-template-rows: var(--three-rows--min);
//   //   //   grid-template-areas:
//   //   //     "A"
//   //   //     "B"
//   //   //     "C";
//   //   //   grid-template-columns: 1fr;
//   //   // }
//   // }
// }

// .agoraGroupcallgridmin--6 {
//   grid-template-rows: var(--two-rows--min);
//   grid-template-areas:
//     "A B C"
//     "D E F";
//   grid-template-columns: 1fr 1fr 1fr;

//   // @media (orientation: portrait) {
//   //   grid-template-rows: var(--three-rows--min);
//   //   grid-template-areas:
//   //     "A B"
//   //     "C D"
//   //     "E F";
//   //   grid-template-columns: 1fr 1fr;
// }

.agoraGroupcallgridmin--5 {
  grid-template-rows: var(--two-rows--min);
  grid-template-areas:
    "A A B B C C"
    "D D D E E E";
  //  grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: initial;

  // @media (orientation: portrait) {
  //   grid-template-rows: var(--three-rows--min);
  //   grid-template-areas:
  //     "A A B B"
  //     "C C D D"
  //     "E E E E";
  //   grid-template-columns: 1fr 1fr;
  // }
}

.agoraGroupcallgridmin--6 {
  grid-template-rows: var(--two-rows--min);
  grid-template-areas:
    "A A A B B B"
    "C C C D D D"
    "E E F F G G";
  grid-template-columns: 1fr 1fr 1fr;

  // @media (orientation: portrait) {
  //   grid-template-rows: var(--three-rows--min);
  //   grid-template-areas:
  //     "A B"
  //     "C D"
  //     "E F";
  //   grid-template-columns: 1fr 1fr;
  // }
}

.agoraGroupcallgridmin--7 {
  grid-template-rows: var(--two-rows--min);
  grid-template-areas:
    "A A A B B B C C C D D D"
    "E E E E F F F F G G G G";
  grid-template-columns: 1fr 1fr 1fr 1fr;

  // @media (orientation: portrait) {
  //   grid-template-rows: var(--three-rows--min);
  //   grid-template-areas:
  //     "A A A B B B"
  //     "C C C D D D"
  //     "E E F F G G";
  //   grid-template-columns: 1fr 1fr 1fr;
  // }
}

.agoraGroupcallgridmin--8 {
  grid-template-rows: var(--two-rows--min);
  grid-template-areas:
    "A B C D"
    "E F G H";
  grid-template-columns: 1fr 1fr 1fr 1fr;

  // @media (orientation: portrait) {
  //   grid-template-rows: var(--four-rows--min);
  //   grid-template-areas:
  //     "A B C D"
  //     "E F G H";
  //   grid-template-columns: 1fr 1fr 1fr 1fr;

  // @media (orientation: portrait) {
  //   grid-template-rows: var(--four-rows--min);
  //   grid-template-areas:
  //     "A B"
  //     "C D"
  //     "E F"
  //     "G H";
  //   grid-template-columns: 1fr 1fr;
  // }
}

// .video-wrapper {
//   height: 100%;
//   overflow: hidden;
//   width: 100%;
// }

// video {
//   height: 100%;
//   display: block;
//   object-fit: cover;
//   width: 100%;
// }

.video-wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

video {
  height: 100%;
  display: block;
  object-fit: cover;
  width: 100%;
}

.controls_groupCall {
  cursor: pointer;
  width: 64px;
  height: 72px;
  background: #56647d;
  border-radius: 16px;
  font-size: 0.75rem;
  line-height: 1.333;
  color: #fff;
  display: flex;
  row-gap: 6px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.video-user-left-label {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;

  position: absolute;
  width: 137px;
  min-height: 68px;
  left: 0px;
  top: 5px;
  z-index: 1;
  background: rgb(23, 43, 77) !important;
  border-radius: 0px 10px 10px 0px;
}
.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
}
.root-div-align {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  /* top: 50px; */
  position: absolute;
  /* left: 50%; */
  width: 100%;
}
.user-detail-label {
  padding-top: 24px;
}
.user-detail-label-local {
  padding-top: 8px;
}
.root-user-div-label {
  position: relative;
  &:hover {
    .video-user-left-label,
    .callControls {
      display: flex;
    }
  }
}
.root-Parent {
  display: contents;
  &:hover {
    .video-user-left-label {
      display: flex;
    }
  }
}
